import { AuthApi } from '../api/auth.api';
import { SuccessResponse } from "../types/auth.response";
import { IApproveVerificationAction } from "../types/auth.type";
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AsyncThunkParams } from "../types/reduxThunk.type";
import { ILogin } from "../interfaces/auth.interface";
import { AxiosError } from "axios";

type CondeStatus = 'INIT' | 'REQUESTED' | 'SUCCESS' | 'ERROR';

type AuthState = {
    email: string,
    isAuth: boolean,
    isLoading: boolean,
    error: string | null,
    codeStatus: CondeStatus
}

const initialState: AuthState = {
    email: '',
    error: null,
    isAuth: false,
    isLoading: false,
    codeStatus: 'INIT',
};

export const fetchAuthEmail = createAsyncThunk<SuccessResponse, string, AsyncThunkParams>(
    'auth/authByEmail',
    async (email, { rejectWithValue }) => {
        try {
            const response = await AuthApi.authEmail(email);

            if (response && response.data) {
                return response.data;
            }
        } catch (e: any) {
            return rejectWithValue(e.message)
        }
    }
);

export const login = createAsyncThunk<undefined, ILogin, AsyncThunkParams>(
    'auth/login',
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthApi.login(data);

            if (response.accessToken && response.refreshToken) {
                localStorage.setItem("accessToken", response.accessToken);
                localStorage.setItem("refreshToken", response.refreshToken);
            }

        } catch (e: unknown) {
            if (e instanceof AxiosError) {
                return rejectWithValue(e.response?.data?.message);
            } else {
                return rejectWithValue(e as string);
            }
        }
    }
);

export const logoutAsync = createAsyncThunk<{ success: boolean }, undefined, AsyncThunkParams>(
    'auth/logout-async',
    async (data, { rejectWithValue }) => {
        try {
            const refreshToken = localStorage.getItem('refreshToken');
            const accessToken = localStorage.getItem('accessToken');

            if (accessToken && refreshToken) {
                localStorage.removeItem("accessToken");
                localStorage.removeItem("refreshToken");
            }

            return { success: true }

        } catch (e: unknown) {
            if (e instanceof AxiosError) {
                return rejectWithValue(e.response?.data?.message);
            } else {
                return rejectWithValue(e as string);
            }
        }
    }
);

export const compareVerificationEmail = createAsyncThunk<void, IApproveVerificationAction, AsyncThunkParams>(
    'auth/compareCode',
    async (data, { rejectWithValue, dispatch, getState }) => {
        const { code } = data;
        const { email } = getState().auth
        const fingerprint = localStorage.getItem('fingerprint');

        try {
            dispatch(setCodeStatus('REQUESTED'));
            const response = await AuthApi.compareVerificationCode({
                code,
                email,
                fingerprint: fingerprint!
            });

            if (response.data.accessToken || response.data.refreshToken) {
                dispatch(setCodeStatus('SUCCESS'));
                dispatch(setIsAuth(true));
                // localStorage.setItem('accessToken', response.data.accessToken);
                // localStorage.setItem('refreshToken', response.data.refreshToken);
            } else {
                return rejectWithValue('You don\'t have access.');
            }
        } catch (e: any) {
            return rejectWithValue(e.response.data.message)
        }
    }
);

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setIsAuth(state, action: PayloadAction<boolean>) {
            state.isAuth = action.payload;
        },
        clearAuth(state) {
            state.error = '';
        },
        logOut(state) {
            state.email = '';
            state.isAuth = false;
        },
        saveEmail(state, action: PayloadAction<string>) {
            state.email = action.payload
        },
        setCodeStatus(state, action: PayloadAction<CondeStatus>) {
            state.codeStatus = action.payload;
        },
        clearAuthStore(state) {
            state.email = ''
            state.error = null
            state.isAuth = false
            state.isLoading = false
            state.codeStatus = 'INIT'
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAuthEmail.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(fetchAuthEmail.fulfilled, (state) => {
            state.error = null;
            state.isLoading = false;
        });
        builder.addCase(fetchAuthEmail.rejected, (state, action) => {
            state.error = action.payload!;
            state.isLoading = false;
        });
        builder.addCase(compareVerificationEmail.pending, (state) => {
            state.isLoading = true;
            state.error = null;
            state.codeStatus = 'REQUESTED';
        });
        builder.addCase(compareVerificationEmail.fulfilled, (state) => {
            state.error = null;
            state.isLoading = false;
            state.codeStatus = 'INIT';
        });
        builder.addCase(compareVerificationEmail.rejected, (state, action) => {
            state.isLoading = false;
            state.codeStatus = 'ERROR';
            state.error = action.payload!;
        });
        builder.addCase(login.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(login.fulfilled, (state) => {
            state.error = null;
            state.isAuth = true;
            state.isLoading = false;
        });
        builder.addCase(login.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload!;
        });
        builder.addCase(logoutAsync.pending, (state) => {
            state.email = '';
            state.isAuth = false;
            state.isLoading = true;
        });
        builder.addCase(logoutAsync.fulfilled, (state) => {
            state.error = null;
            state.isLoading = false;
        });
        builder.addCase(logoutAsync.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload!;
        });
    }
});

export const {
    logOut,
    clearAuth,
    setIsAuth,
    setCodeStatus,
    clearAuthStore,
} = authSlice.actions;

export default authSlice.reducer;

