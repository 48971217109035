import { InvitationsListProps } from "./interfaces";
import InvitationShow from "./InvitationShow";
import { useCallback } from "react";
import { useAppDispatch } from "../../hooks/redux";
import { deleteInvite } from "../../store/reducers/InvitationsSlice";

const InvitationsList = ({ invitations }: InvitationsListProps) => {
    const dispatch = useAppDispatch();

    const handleDeleteInvite = useCallback((id: string) => {
        dispatch(deleteInvite(id));
    }, [dispatch]);

    return (
        <>
            {invitations.map(invite => <InvitationShow onDelete={handleDeleteInvite} key={invite.id} invite={invite}/>)}
        </>
    );
}

export default InvitationsList;