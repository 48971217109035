import { AdminsApi } from "../api/admins.api";
import { AdminModel } from "../models/Admin.model";
import { AsyncThunkParams } from "../types/reduxThunk.type";
import { Pagination, SendWarning } from "../interfaces/common.interface";
import { ICreateAdmin } from "../interfaces/admin.interface";
import { AdminsListResponse } from "../types/admins.response";
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { UserModel } from "../models/User.model";
import { SuccessResponse } from "../types/auth.response";

type AdminState = {
  lastPage: number,
  isLoading: boolean,
  users: AdminModel[],
  error: string | null,
  isHardDeleting: boolean,
  deletedUsers: UserModel[],
}

const initialState: AdminState = {
  error: null,
  users: [],
  lastPage: 0,
  isLoading: false,
  deletedUsers: [],
  isHardDeleting: false,
};

const apiAdmin = new AdminsApi();

export const fetchListUsers = createAsyncThunk<AdminsListResponse, Pagination, AsyncThunkParams>(
    'admins/list',
    async (pagination, { rejectWithValue }) => {
      try {
        return apiAdmin.getList(pagination);
      } catch (e: any) {
        return rejectWithValue(e.message)
      }
    }
);

export const getSoftDeletedUsers = createAsyncThunk<UserModel[], undefined, AsyncThunkParams>(
    'admins/get-soft-deleted-users',
    async (_, { rejectWithValue }) => {
      try {
        return apiAdmin.getSoftDeletedUsers();
      } catch (e: any) {
        return rejectWithValue(e.message)
      }
    }
);

export const forceDeleteUser = createAsyncThunk<SuccessResponse, { id: number }, AsyncThunkParams>(
    'admins/force-delete-user',
    async ({ id }, { rejectWithValue }) => {
      try {
        return apiAdmin.forceDeleteUser(id);
      } catch (e: any) {
        return rejectWithValue(e.message)
      }
    }
);

export const createAdmin = createAsyncThunk<AdminModel, ICreateAdmin, AsyncThunkParams>(
    'admins/create',
    async (user, { rejectWithValue }) => {
      try {
        return apiAdmin.create({ ...user, role: 'admin' });
      } catch (e: any) {
        return rejectWithValue(e.message)
      }
    }
);

export const sendWarning = createAsyncThunk<undefined, SendWarning, AsyncThunkParams>(
    'admins/send-warning',
    async (warningData, { rejectWithValue }) => {
      try {
        await apiAdmin.sendWarning(warningData);
      } catch (e: any) {
        return rejectWithValue(e.message)
      }
    }
);

export const adminSlice = createSlice({
  name: 'admins',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchListUsers.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(fetchListUsers.fulfilled, (state, action) => {
      state.error = null;
      state.isLoading = false;
      //@ts-ignore
      state.users = action.payload;
      state.lastPage = action.payload.lastPage;
    });
    builder.addCase(fetchListUsers.rejected, (state, action) => {
      state.error = action.payload!;
      state.isLoading = false;
    });
    builder.addCase(createAdmin.pending, (state) => {
      state.error = null;
      state.isLoading = true;
    });
    builder.addCase(createAdmin.fulfilled, (state, action) => {
      state.error = null;
      state.isLoading = false;
      state.users.push(action.payload)
    });
    builder.addCase(createAdmin.rejected, (state, action) => {
      state.error = action.payload!;
      state.isLoading = false;
    });
    builder.addCase(getSoftDeletedUsers.pending, (state) => {
      state.error = null;
      state.isLoading = true;
    });
    builder.addCase(getSoftDeletedUsers.fulfilled, (state, action) => {
      state.error = null;
      state.isLoading = false;
      state.deletedUsers = action.payload;
    });
    builder.addCase(getSoftDeletedUsers.rejected, (state, action) => {
      state.error = action.payload!;
      state.isLoading = false;
    });
    builder.addCase(forceDeleteUser.pending, (state) => {
      state.error = null;
      state.isLoading = true;
      state.isHardDeleting = true;
    });
    builder.addCase(forceDeleteUser.fulfilled, (state, action) => {
      state.error = null;
      state.isLoading = false;
      state.isHardDeleting = false;
      state.deletedUsers = state.deletedUsers.filter(user => user.id !== action.meta.arg.id);
    });
    builder.addCase(forceDeleteUser.rejected, (state, action) => {
      state.error = action.payload!;
      state.isHardDeleting = false;
      state.isLoading = false;
    });
  }
});

export default adminSlice.reducer;

