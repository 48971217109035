import styles from './styles/EmailAuth.module.scss';
import { ChangeEvent, useCallback, useState } from 'react';
import { clearAuth, login } from "../../store/reducers/AuthSlice";
import { useAppDispatch, useAppSelector } from '../../hooks/redux';

interface LoginValues {
    email: string;
    password: string;
}

const EmailAuth = () => {
    const dispatch = useAppDispatch();
    const [value, setValue] = useState<LoginValues>({
        email: '',
        password: ''
    });

    const { isLoading, error } = useAppSelector(state => state.auth);

    const onSubmit = useCallback((e: any) => {
        e.preventDefault();
        dispatch(login(value));
    }, [value, dispatch]);

    const onChangeEmail = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setValue(prev => ({ ...prev, email: e.target.value }));
        if (error) {
            dispatch(clearAuth());
        }
    }, [error, dispatch]);

    const onChangePassword = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setValue(prev => ({ ...prev, password: e.target.value }));
        if (error) {
            dispatch(clearAuth());
        }
    }, [error, dispatch]);

    return (
        <div className={styles.container}>
            <div className={styles.formContainer}>
                <form onSubmit={onSubmit}>
                    <input
                        required
                        id="email"
                        type="email"
                        style={(error && error.length) ? {
                            borderWidth: 1,
                            border: 'solid',
                            color: "#DC2626",
                            borderColor: '#DC2626',
                        } : undefined}
                        value={value.email}
                        onChange={onChangeEmail}
                        className={styles.emailInput}
                        placeholder="email@gmail.com"
                    />
                    <input
                        required
                        id="password"
                        value={value.password}
                        type="password"
                        placeholder="password"
                        style={(error && error.length) ? {
                            borderWidth: 1,
                            border: 'solid',
                            color: "#DC2626",
                            borderColor: '#DC2626',
                        } : undefined}
                        onChange={onChangePassword}
                        className={styles.emailInput}
                    />
                    <button
                        type='submit'
                        disabled={isLoading}
                        className={isLoading ? styles.submitButtonLoading : styles.submitButton}
                        style={(error && error.length) ? {
                            borderWidth: 1,
                            border: 'solid',
                            color: "#DC2626",
                            borderColor: '#DC2626',
                        } : undefined}
                    >
                        {isLoading ? "Please wait..." : "Send"}
                    </button>
                </form>
                <div>
                    {error && <h3 className="text-center text-red-600">{error}</h3>}
                </div>
            </div>
        </div>
    )
}

export default EmailAuth;