import styles from './Modal.module.scss';
import { IoClose } from "react-icons/io5";

interface IModalProps {
    isVisible: boolean;
    onClose: VoidFunction;
    children?: JSX.Element;
}

const Modal = ({ isVisible, onClose, children }: IModalProps) => {
    if (!isVisible) return null;

    const handleModal = (event: any) => {
        if (event.target.id === 'wrapper') {
            onClose();
        }
    }

    return (
        <div
            id="wrapper"
            onClick={handleModal}
            className={styles.modalWrapper}
        >
            <div className={styles.contentWrapper}>
                <button onClick={onClose} className={styles.closeButton}><IoClose/></button>
                <div className={styles.childrenWrapper}>{children}</div>
            </div>
        </div>
    )
}

export default Modal;