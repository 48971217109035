import { Pagination } from "../interfaces/common.interface";
import { AsyncThunkParams } from "../types/reduxThunk.type";
import { QuestionReportsApi } from "../api/questionReports.api";
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { QuestionReportModel } from "../models/QuestionReport.model";
import { IQuestionReportListResponse } from "../types/questionReports.response";

type QuestionReportsState = {
    lastPage: number,
    actionStatus?: '',
    isLoading: boolean,
    error: string | null,
    reports: QuestionReportModel[],
}

const initialState: QuestionReportsState = {
    error: null,
    reports: [],
    lastPage: 0,
    isLoading: false,
};

const apiReports = new QuestionReportsApi();

export const fetchListQuestionReports = createAsyncThunk<IQuestionReportListResponse, Pagination, AsyncThunkParams>(
    'questionReports/list',
    async (pagination, { rejectWithValue }) => {
        try {
            return apiReports.getList(pagination);
        } catch (e: any) {
            return rejectWithValue(e.message)
        }
    }
);

export const deleteQuestion = createAsyncThunk<boolean, number, AsyncThunkParams>(
    'questionReports/delete-question',
    async (id, { rejectWithValue }) => {
        try {
            return apiReports.deleteQuestion(id);
        } catch (e: any) {
            return rejectWithValue(e.message)
        }
    }
);

export const deleteQuestionReport = createAsyncThunk<boolean, number, AsyncThunkParams>(
    'questionReports/delete-report',
    async (id, { rejectWithValue }) => {
        try {
            return apiReports.deleteReport(id);
        } catch (e: any) {
            return rejectWithValue(e.message)
        }
    }
);

export const questionReportsSlice = createSlice({
    name: 'questionReports',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchListQuestionReports.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(fetchListQuestionReports.fulfilled, (state, action) => {
            state.error = null;
            state.isLoading = false;
            state.reports = action.payload.records;
            state.lastPage = action.payload.lastPage;
        });
        builder.addCase(fetchListQuestionReports.rejected, (state, action) => {
            state.error = action.payload!;
            state.isLoading = false;
        });
        builder.addCase(deleteQuestion.pending, (state, action) => {
            state.error = null;
            state.isLoading = true;
            state.reports = state.reports.filter(report => report.question.id !== action.meta.arg);
        });
        builder.addCase(deleteQuestion.fulfilled, (state) => {
            state.error = null;
            state.isLoading = false;
        });
        builder.addCase(deleteQuestion.rejected, (state, action) => {
            state.error = action.payload!;
            state.isLoading = false;
        });
        builder.addCase(deleteQuestionReport.pending, (state, action) => {
            state.error = null;
            state.isLoading = true;
            state.reports = state.reports.filter(report => report.id !== action.meta.arg);
        });
        builder.addCase(deleteQuestionReport.fulfilled, (state) => {
            state.error = null;
            state.isLoading = false;
        });
        builder.addCase(deleteQuestionReport.rejected, (state, action) => {
            state.error = action.payload!;
            state.isLoading = false;
        });
    }
});

export default questionReportsSlice.reducer;

