import EmailAuth from '../pages/Auth/EmailAuth';
import { Navigate, Route, Routes } from 'react-router-dom';
import React from "react";

const LoginRoutes = () => {
    return (
        <Routes>

            <Route path="/email" element={<EmailAuth/>}/>
            <Route path="/" element={<Navigate to="/email"/>}/>

            <Route path='*' element={<Navigate to='/email'/>}/>
        </Routes>
    )
}

export default LoginRoutes;