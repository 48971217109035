import { createPopper } from "@popperjs/core";
import React, { useEffect, useRef } from "react";

const Popover = ({ show }: { show: boolean }) => {
    const btnRef = useRef<HTMLButtonElement>(null);
    const popoverRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (show && btnRef.current && popoverRef.current) {
            createPopper(btnRef.current, popoverRef.current, {
                placement: "top"
            });
        }
    }, [show, btnRef]);

    return (
        <div className="absolute w-[300px] right-0 bottom-10 flex flex-wrap">
            <div className="w-full text-center">
                <div
                    ref={popoverRef}
                    className={`${show ? "opacity-75" : "hidden"} transition ease-in-out delay-150 resize-none bg-red-600 border-0 mb-3 block z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg`}
                >
                    <div className="text-white p-3">
                        You have already added an invite with this email or phone number.
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Popover;

