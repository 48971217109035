import Modal from '../Modal/Modal';
import Video from '../Videos/Video';
import { useCallback, useState } from 'react';
import styles from './styles/Report.module.scss';
import { useAppDispatch } from '../../hooks/redux';
import AcceptReport from "../AcceptReport/AcceptReport";
import { EReportType } from "../AcceptReport/AcceptReport.interface";
import { QuestionReportModel } from "../../store/models/QuestionReport.model";
import { deleteQuestionReport } from "../../store/reducers/QuestionReportSlice";

interface IReportProps {
    report: QuestionReportModel;
}

const QuestionReport = ({ report }: IReportProps) => {
    const [showModal, setShowModal] = useState(false);
    const [showModalReport, setShowModalReport] = useState(false);

    const dispatch = useAppDispatch();

    const handleModal = useCallback(() => {
        setShowModal(!showModal);
    }, [showModal]);

    const handleAcceptReport = useCallback(() => {
        setShowModalReport(!showModalReport);
    }, [showModalReport]);

    const handleDeclineReport = useCallback((id: number) => () => {
        dispatch(deleteQuestionReport(id));
    }, [dispatch]);

    return (
        <tbody>
        <tr className={styles.reportRowsWrapper}>
            <td className={styles.tableRow}>
                {report.question.description}
            </td>
            <th scope="row" className={styles.videoRow}>
                {report?.question?.videoUrl ? <>
                    <button onClick={handleModal}>
                        <Video url={report?.question?.videoUrl}/>
                    </button>
                    <Modal isVisible={showModal} onClose={handleModal}>
                        <Video autoPlay={true} url={report?.question?.videoUrl} className={styles.videoModal}/>
                    </Modal>
                </> : <span>No video content</span>}
            </th>
            <td className={styles.tableRow}>
                {report.question.createdBy}
            </td>
            <td className={styles.tableRow}>
                {report.question.creator?.userName}
            </td>
            <td className={styles.tableRow}>
                {report.reporter.id}
            </td>
            <td className={styles.tableRow}>
                {report.reporter.userName}
            </td>
            <td className={styles.tableRow}>
                {report.text}
            </td>
            <td className={styles.tableRow}>
                <div className={styles.buttonsWrapper}>
                    <div>
                        <button name={'warning'} onClick={handleAcceptReport} className={styles.acceptButton}>
                            Accept
                        </button>
                        <Modal isVisible={showModalReport} onClose={handleAcceptReport}>
                            <AcceptReport
                                contentId={report.question.id}
                                reportType={EReportType.QUESTION}
                                contentOwner={report!.question!.creator!}
                            />
                        </Modal>
                    </div>
                    <button onClick={handleDeclineReport(report.id)} className={styles.declineButton}>
                        Decline
                    </button>
                </div>
            </td>
        </tr>
        </tbody>
    )
}

export default QuestionReport;



