import styles from './styles/TableHeader.module.scss';
import { useLocation } from "react-router-dom";
import { useMemo } from "react";

const UserTableHeader = () => {
    const location = useLocation();
    const isDeletedUsersPath = useMemo(() => location.pathname === '/deleted-users', [location]);

    return (
        <thead className={styles.tableHeader}>
        <tr>
            <th scope="col" className={styles.headerCol}>
                User name
            </th>
            <th scope="col" className={styles.headerCol}>
                User phone
            </th>
            <th scope="col" className={styles.headerCol}>
                User email
            </th>
            {
                isDeletedUsersPath ? <>
                    <th scope="col" className={styles.headerCol}>
                        Deleted Date
                    </th>
                    <th scope="col" className={styles.headerButtonsCol}>
                        Accept Deletion
                    </th>
                </> : null
            }
        </tr>
        </thead>
    )
}

export default UserTableHeader;



