import './App.css';
import React from 'react';
import Routing from './routes';
import { persist } from './store';
import { PersistGate } from 'redux-persist/integration/react';

const App = () => {
    return (
        <PersistGate persistor={persist}>
            <Routing/>
        </PersistGate>
    );
}

export default App;
