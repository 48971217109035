import QuestionReport from './Report';
import { useAppSelector } from '../../hooks/redux';

const QuestionReportsList = () => {
    const { reports } = useAppSelector(state => state.questionReports);

    return (
        <>
            {
                reports && reports.length ? reports?.map(report => <QuestionReport key={report.id}
                                                                                   report={report}/>) : null
            }
        </>
    )
}

export default QuestionReportsList;