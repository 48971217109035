import styles from "../VideoReports/VideoReports.module.scss";
import QuestionReportsList from "../../components/QuestionReports/QuestionReportsList";
import QuestionTableHeader from "../../components/QuestionReports/QuestionTableHeader";
import Pagination from "../../components/Pagination/Pagination";
import { EPaginationType } from "../../hooks/usePagination";
import { useAppSelector } from "../../hooks/redux";

const QuestionReports = () => {
    const { reports } = useAppSelector(state => state.questionReports);
    return (
        <div className={styles.wrapper}>
            {reports.length ? <>
                    <table className={styles.tableList}>
                        <QuestionTableHeader/>
                        <QuestionReportsList/>
                    </table>
                    <div
                        className="mt-[20px] h-[50px] absolute bottom-10 left-0 right-0 flex justify-center content-center">
                        <Pagination type={EPaginationType.QUESTION_REPORTS}/>
                    </div>
                </>
                : <h1 className="text-white text-center mt-[20px]">The report list is empty.</h1>
            }
        </div>
    )
}

export default QuestionReports;