import styles from './Invitations.module.scss';

const InvitationsHeader = () => {
    return (
        <div className={styles.invitationHeader}>
            <h3 className={styles.headerItem}>Email</h3>
            <h3 className={styles.headerItem}>PhoneNumber</h3>
            <h3 className={styles.headerItem}>Message</h3>
        </div>
    );
}

export default InvitationsHeader;