import { VideoReportsApi } from '../api/videoReports.api';
import { Pagination } from "../interfaces/common.interface";
import { AsyncThunkParams } from "../types/reduxThunk.type";
import { VideoReportModel } from '../models/VideoReport.model';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { VideoReportsResponse } from "../types/videoReports.response";

type VideoReportsState = {
    actionStatus?: '',
    isLoading: boolean,
    error: string | null,
    lastPage: number,
    reports: VideoReportModel[],
}

const initialState: VideoReportsState = {
    error: null,
    reports: [],
    lastPage: 0,
    isLoading: false,
};

const apiReports = new VideoReportsApi();

export const fetchListReports = createAsyncThunk<VideoReportsResponse, Pagination, AsyncThunkParams>(
    'videoReports/list',
    async (data, { rejectWithValue }) => {
        try {
            return apiReports.getList(data);
        } catch (e: any) {
            return rejectWithValue(e.message)
        }
    }
);

export const deleteVideo = createAsyncThunk<boolean, number, AsyncThunkParams>(
    'videoReports/delete-video',
    async (id, { rejectWithValue }) => {
        try {
            return apiReports.deleteVideo(id);
        } catch (e: any) {
            return rejectWithValue(e.message)
        }
    }
);

export const deleteReport = createAsyncThunk<boolean, number, AsyncThunkParams>(
    'videoReports/delete-report',
    async (id, { rejectWithValue }) => {
        try {
            return apiReports.deleteReport(id);
        } catch (e: any) {
            return rejectWithValue(e.message)
        }
    }
);

export const videoReportsSlice = createSlice({
    name: 'videoReports',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchListReports.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(fetchListReports.fulfilled, (state, action) => {
            state.error = null;
            state.isLoading = false;
            state.reports = action.payload.records;
            state.lastPage = action.payload.lastPage;
        });
        builder.addCase(fetchListReports.rejected, (state, action) => {
            state.error = action.payload!;
            state.isLoading = false;
        });
        builder.addCase(deleteVideo.pending, (state, action) => {
            state.error = null;
            state.isLoading = true;
            state.reports = state.reports.filter(report => report.video.id !== action.meta.arg);
        });
        builder.addCase(deleteVideo.fulfilled, (state) => {
            state.error = null;
            state.isLoading = false;
        });
        builder.addCase(deleteVideo.rejected, (state, action) => {
            state.error = action.payload!;
            state.isLoading = false;
        });
        builder.addCase(deleteReport.pending, (state, action) => {
            state.error = null;
            state.isLoading = true;
            state.reports = state.reports.filter(report => report.id !== action.meta.arg);
        });
        builder.addCase(deleteReport.fulfilled, (state) => {
            state.error = null;
            state.isLoading = false;
        });
        builder.addCase(deleteReport.rejected, (state, action) => {
            // @ts-ignore
            state.error = action.payload!;
            state.isLoading = false;
        });
    }
});

export default videoReportsSlice.reducer;

