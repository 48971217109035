import { FC } from "react";
import { classes } from "./style";
import { cls } from "../../utils/helpers/cls";
import { ButtonProps, ESizeButton, EVariationButton } from "./Button.interface";

const Button: FC<ButtonProps> = ({
                                     children,
                                     rounded,
                                     outline,
                                     className,
                                     size = ESizeButton.normal,
                                     variation = EVariationButton.primary,
                                     ...props
                                 }) => {

    return (
        <button
            {...props}
            type="button"
            className={cls(`
                ${classes.size[size]}
                ${rounded && classes.rounded}
                ${classes.variant[variation]}
                ${outline && classes.outline}
                ${props.disabled && classes.disabled}
                ${className}
            `)}
        >
            {children}
        </button>
    )
}

export default Button;