import { PaginationType, usePagination } from "../../hooks/usePagination";

const Pagination = ({ type }: { type: PaginationType }) => {
    const {
        page,
        lastPage,
        handlePrevPage,
        handleNextPage,
        handleSelectPage,
    } = usePagination({ type });

    const range = (start: number, end: number): number[] => {
        let output = [];

        for (let i = start; i <= end; i++) {
            //@ts-ignore
            output.push(i);
        }

        return output;
    };

    return (
        <nav>
            <ul className="inline-flex items-center -space-x-px">
                <li>
                    <button
                        disabled={page === 1}
                        onClick={handlePrevPage}
                        className="block px-3 py-2 ml-0 leading-tight text-gray-500
            bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100
             hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700
             dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white disabled:opacity-50">
                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1
            1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z">
                            </path>
                        </svg>
                    </button>
                </li>
                {
                    range(1, lastPage).map((num, i) => (
                        <button key={i} onClick={handleSelectPage(num)} className={`px-3 py-2 leading-tight text-gray-500
          border border-gray-300 hover:bg-gray-100 hover:text-gray-700 ${page === num ? 'bg-gray-900' : 'bg-gray-800'}
          dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
                        >
                            {num}
                        </button>
                    ))
                }
                <li>
                    <button
                        onClick={handleNextPage}
                        disabled={lastPage === page}
                        className="block px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 disabled:opacity-50
            hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                            >
                            </path>
                        </svg>
                    </button>
                </li>
            </ul>
        </nav>
    )
}

export default Pagination;