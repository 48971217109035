import React from 'react';
import { Tooltip } from "react-tooltip";

const Message = ({ content }: { content: string }) => {
    const maxLength = 30;

    const truncatedContent = content.length > maxLength ? `${content.slice(0, maxLength)}...` : content;

    return (
        <div>
            <div className="cursor-pointer" data-tooltip-id={content}>
                {truncatedContent}
            </div>
            <Tooltip id={content} hidden={content.length < maxLength}>
                <div className="whitespace-pre-wrap max-w-[300px]">
                    <span className="break-all whitespace-pre-wrap">{content}</span>
                </div>
            </Tooltip>
        </div>
    );
};

export default Message;
