import React from "react";
import { Navigate, Route, Routes } from 'react-router-dom';
import Users from "../pages/Users/Users";
import Invitations from "../pages/Invitations/Invitations";
import VideoReports from '../pages/VideoReports/VideoReports';
import DeletedUsers from "../pages/DeletedUsers/DeletedUsers";
import CommentReports from "../pages/CommentReports/CommentReports";
import QuestionReports from "../pages/QuestionReports/QuestionReports";

const MainRoutes = () => {
    return (
        <Routes>
            <Route path="/videos" element={<VideoReports/>}/>
            <Route path="*" element={<Navigate to="/videos"/>}/>

            <Route path="/questions" element={<QuestionReports/>}/>
            <Route path="*" element={<Navigate to="/questions"/>}/>

            <Route path="/comments" element={<CommentReports/>}/>
            <Route path="*" element={<Navigate to="/comments"/>}/>

            <Route path="/users" element={<Users/>}/>
            <Route path="*" element={<Navigate to="/users"/>}/>

            <Route path="/invitations" element={<Invitations/>}/>
            <Route path="*" element={<Navigate to="/invitations"/>}/>

            <Route path="/deleted-users" element={<DeletedUsers/>}/>
            <Route path="*" element={<Navigate to="/deleted-users"/>}/>
        </Routes>
    )
}

export default MainRoutes;