import styles from "../VideoReports/VideoReports.module.scss";
import Pagination from "../../components/Pagination/Pagination";
import { EPaginationType } from "../../hooks/usePagination";
import CommentTableHeader from "../../components/CommentReports/CommentTableHeader";
import CommentReportsList from "../../components/CommentReports/CommentReportsList";

const CommentReports = () => {
    return (
        <div className={styles.wrapper}>
            <table className={styles.tableList}>
                <CommentTableHeader/>
                <CommentReportsList/>
            </table>
            <div className="mt-[20px] h-[50px] absolute bottom-10 left-0 right-0 flex justify-center content-center">
                <Pagination type={EPaginationType.COMMENTS_REPORTS}/>
            </div>
        </div>
    )
}

export default CommentReports;