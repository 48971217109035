import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import User from "./User";
import { useCallback } from "react";
import { forceDeleteUser } from "../../store/reducers/AdminSlice";

interface Props {
  isDeletedUsers?: boolean
}

const UsersList = ({ isDeletedUsers }: Props) => {
  const dispatch = useAppDispatch();
  const { users } = useAppSelector(state => state.admins);
  const { deletedUsers } = useAppSelector(state => state.admins);

  const handleForceDeleteUser = useCallback((id: number) => {
    dispatch(forceDeleteUser({ id }));
  }, [dispatch]);

  return (
      <>
        {
          isDeletedUsers && deletedUsers && deletedUsers.length ? deletedUsers?.map(user => <User
              user={user}
              key={user.id}
              onClick={handleForceDeleteUser}
          />) : null
        }
        {
          !isDeletedUsers && users && users.length ? users?.map(user => <User user={user} key={user.id}/>
          ) : null
        }
      </>
  )
}

export default UsersList;