import Report from "./Report";
import { useAppSelector } from '../../hooks/redux';

const VideoReportsList = () => {
    const { reports } = useAppSelector(state => state.videoReports);

    return (
        <>
            {
                reports && reports.length ? reports?.map(report => <Report key={report.id} report={report}/>) : null
            }
        </>
    )
}

export default VideoReportsList;