import { ButtonHTMLAttributes, HTMLProps, ReactNode } from "react";

export enum EVariationButton {
    error = 'error',
    primary = 'primary',
    success = 'success',
    warning = 'warning',
    secondary = 'secondary',
}

export enum ESizeButton {
    small = 'small',
    normal = 'normal',
    large = 'large',
}

type VariationTypes =
    EVariationButton.primary
    | EVariationButton.error
    | EVariationButton.success
    | EVariationButton.secondary
    | EVariationButton.warning;

type ButtonSize = ESizeButton.large | ESizeButton.normal | ESizeButton.small;

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    size?: ButtonSize;
    rounded?: boolean;
    outline?: boolean;
    children: ReactNode;
    variation?: VariationTypes;
    className?: HTMLProps<HTMLButtonElement>["className"];
}