import styles from './Users.module.scss';
import { useCallback, useState } from "react";
import Modal from "../../components/Modal/Modal";
import UsersList from "../../components/Users/UsersList";
import CreateUser from "../../components/Users/CreateUser";
import { EPaginationType } from "../../hooks/usePagination";
import Pagination from '../../components/Pagination/Pagination';
import UserTableHeader from "../../components/Users/UsersHeader";

const Users = () => {
    const [showModal, setShowModal] = useState<boolean>(false);

    const handleModal = useCallback(() => {
        setShowModal(!showModal);
    }, [showModal]);

    const handleOpenModal = () => {
        setShowModal(true);
    };

    return (
        <div className={styles.wrapper}>
            <table className={styles.tableList}>
                <UserTableHeader/>
                <UsersList/>
            </table>
            <div className="flex justify-center w-full">
                <button onClick={handleOpenModal} className="text-white p-2 mt-5 bg-green-700 rounded">
                    Create user
                </button>
                <Modal isVisible={showModal} onClose={handleModal}>
                    <CreateUser closeModal={handleModal}/>
                </Modal>
            </div>
            <div className="mt-[20px] h-[50px] left-50 bottom-0 flex justify-center content-center">
                <Pagination type={EPaginationType.USERS}/>
            </div>
        </div>
    )
}

export default Users;

