import { ChangeEvent, useCallback, useState } from 'react';
import TextInput from "../Input/TextInput";
import styles from './styles/Report.module.scss';
import { useAppDispatch } from '../../hooks/redux';
import { AdminModel } from "../../store/models/Admin.model";
import { createAdmin } from "../../store/reducers/AdminSlice";

type ICreateUserDto = Pick<AdminModel, 'email' | 'phoneNumber' | 'userName'> & { password: string };

const CreateUser = ({ closeModal }: { closeModal: VoidFunction }) => {
    const dispatch = useAppDispatch();
    const [userData, setUserData] = useState<ICreateUserDto>({
        email: '',
        userName: '',
        password: '',
        phoneNumber: '',
    });

    const handleCreateUser = useCallback((e: any) => {
        e.preventDefault();
        dispatch(createAdmin(userData));
        setUserData({
            email: '',
            userName: '',
            password: '',
            phoneNumber: '',
        });
        closeModal();
    }, [dispatch, userData]);

    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const name = e.target.name;

        setUserData(prev => ({ ...prev, [name]: value }));
    }, []);

    return (
        <div className="h-[270px]">
            <form className="h-full" onSubmit={handleCreateUser}>
                <div className="flex flex-col justify-between h-full">
                    <div>
                        <TextInput
                            required
                            type="text"
                            name="userName"
                            placeholder="User name"
                            onChange={handleChange}
                            value={userData?.userName}
                        />
                    </div>
                    <div>
                        <TextInput
                            required
                            type="text"
                            name="phoneNumber"
                            onChange={handleChange}
                            placeholder="User phone"
                            value={userData?.phoneNumber}
                        />
                    </div>
                    <div>
                        <TextInput
                            required
                            name="email"
                            type="text"
                            placeholder="Email"
                            onChange={handleChange}
                            value={userData?.email}
                        />
                    </div>
                    <div>
                        <TextInput
                            required
                            name="password"
                            type="password"
                            placeholder="Password"
                            onChange={handleChange}
                            value={userData?.password}
                        />
                    </div>
                    <div className={styles.tableRow}>
                        <button type="submit" className="text-white p-2 mt-5 bg-green-700 rounded">
                            Create user
                        </button>
                    </div>
                </div>
            </form>
        </div>

    )
}

export default CreateUser;



