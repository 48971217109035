import styles from './VideoReports.module.scss';
import VideoReportsList from '../../components/VideoReports/VideoReportsList';
import ReportHeader from '../../components/VideoReports/TableHeader';
import Pagination from '../../components/Pagination/Pagination';
import { EPaginationType } from "../../hooks/usePagination";

const VideoReports = () => {
    return (
        <div className={styles.wrapper}>
            <table className={styles.tableList}>
                <ReportHeader/>
                <VideoReportsList/>
            </table>
            <div
                className="mt-[20px] h-[50px] absolute bottom-10 left-0 right-0 flex justify-center content-center">
                <Pagination type={EPaginationType.VIDEO_REPORTS}/>
            </div>
        </div>
    )
}

export default VideoReports;

