import { InvitationsApi } from "../api/invitations.api";
import { AsyncThunkParams } from "../types/reduxThunk.type";
import { InvitationModel } from "../models/Invitation.model";
import { IInvitationDto } from "../interfaces/invitation.interface";
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

type InvitationsState = {
    isLoading: boolean,
    error: string | null,
    invitations: IInvitationDto[],
}

const initialState: InvitationsState = {
    error: null,
    invitations: [],
    isLoading: false,
};

const apiInvitations = new InvitationsApi();

export const sendInvitations = createAsyncThunk<boolean, InvitationModel[], AsyncThunkParams>(
    'invitations/send',
    async (invitations, { rejectWithValue }) => {
        try {
            return apiInvitations.send(invitations);
        } catch (e: any) {
            return rejectWithValue(e.message)
        }
    }
);

export const invitationsSlice = createSlice({
    name: 'invitations',
    initialState,
    reducers: {
        createInvite: (state, action: PayloadAction<IInvitationDto>) => {
            state.invitations.push(action.payload)
        },
        deleteInvite: (state, action: PayloadAction<string>) => {
            state.invitations = state.invitations.filter(invite => invite.id !== action.payload);
        }
    },
    extraReducers: (builder) => {
        builder.addCase(sendInvitations.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(sendInvitations.fulfilled, (state) => {
            state.error = null;
            state.isLoading = false;
            state.invitations = [];
        });
        builder.addCase(sendInvitations.rejected, (state, action) => {
            state.error = action.payload!;
            state.isLoading = false;
        });
    }
});

export const { createInvite, deleteInvite } = invitationsSlice.actions;
export default invitationsSlice.reducer;

