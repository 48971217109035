import { API_URL } from "../urls";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { TokenPairResponse } from "../types/auth.response";


export class ApiService {
  adminAuthUrl: string;
  axiosInstance: AxiosInstance;
  isRefreshRequesting: boolean;
  requestsToRefresh: Array<(newToken: string | null) => void>;

  constructor(config: AxiosRequestConfig = {}) {
    this.requestsToRefresh = [];
    this.isRefreshRequesting = false;
    this.adminAuthUrl = `${API_URL}/admin-auth`;

    this.axiosInstance = axios.create({
      baseURL: API_URL,
      headers: { Accept: 'application/json' },
      ...config,
    });

    this.setupAxiosAuthHeader();
    this.setupAxiosResponseInterceptor();
  }

  private handleFulfilledAxiosResponse = (response: AxiosResponse) => {
    return response.data ? response.data : response;
  };

  private handleRejectedAxiosResponse = async (error: any) => {
    const { status } = error.response || {};
    const originalRequest = error.config;

    if (status === 401 && !originalRequest._isRetry) {
      if (this.isRefreshRequesting) {
        return new Promise((resolve, reject) => {
          this.requestsToRefresh.push((newToken: string | null) => {
            if (newToken) {
              originalRequest.headers.Authorization = `Bearer ${newToken}`;
              resolve(this.axiosInstance.request(originalRequest));
            } else {
              reject(error);
            }
          });
        });
      }

      this.isRefreshRequesting = true;
      originalRequest._isRetry = true;

      try {
        const refreshToken = localStorage.getItem("refreshToken");
        const fingerprint = localStorage.getItem("fingerprint");

        const response: TokenPairResponse = await this.axiosInstance.post(`${this.adminAuthUrl}/refresh-token`, {
          fingerprint,
          token: refreshToken
        });

        await this.removeTokens();
        await this.setTokens(response);

        this.requestsToRefresh.forEach(callback => callback(response.accessToken));
        this.requestsToRefresh = [];

        this.isRefreshRequesting = false;
        originalRequest.headers.Authorization = `Bearer ${response.accessToken}`;
        return this.axiosInstance.request(originalRequest);
      } catch (e) {
        await this.removeTokens();
        this.requestsToRefresh.forEach(callback => callback(null));
        this.requestsToRefresh = [];
        this.isRefreshRequesting = false;
        throw e;
      }
    }

    throw error;
  };

  private setupAxiosAuthHeader() {
    this.axiosInstance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
      const accessToken = localStorage.getItem("accessToken");

      if (accessToken) {
        config!.headers!.Authorization = `Bearer ${accessToken}`;
      }

      return config;
    });
  }

  private setupAxiosResponseInterceptor() {
    this.axiosInstance.interceptors.response.use(
        this.handleFulfilledAxiosResponse,
        this.handleRejectedAxiosResponse
    );
  }

  private async removeTokens(): Promise<void> {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("fingerprint");
    localStorage.removeItem("refreshToken");
  }

  private async setTokens({ accessToken, refreshToken }: TokenPairResponse): Promise<void> {
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
  }
}