import { AsyncThunkParams } from "../types/reduxThunk.type";
import { Pagination } from "../interfaces/common.interface";
import { CommentReportsApi } from "../api/commentReports.api";
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CommentReportModel } from "../models/CommentReport.model";
import { ICommentReportListResponse } from "../types/commentReports.response";

type CommentReportsState = {
    lastPage: number,
    actionStatus?: '',
    isLoading: boolean,
    error: string | null,
    reports: CommentReportModel[]
}

const initialState: CommentReportsState = {
    error: null,
    reports: [],
    lastPage: 0,
    isLoading: false,
};

const apiReports = new CommentReportsApi();

export const fetchListCommentReports = createAsyncThunk<ICommentReportListResponse, Pagination, AsyncThunkParams>(
    'commentReports/list',
    async (pagination, { rejectWithValue }) => {
        try {
            return apiReports.getList(pagination);
        } catch (e: any) {
            return rejectWithValue(e.message)
        }
    }
);

export const deleteComment = createAsyncThunk<boolean, number, AsyncThunkParams>(
    'tagReports/delete-comment',
    async (id, { rejectWithValue }) => {
        try {
            return apiReports.deleteComment(id);
        } catch (e: any) {
            return rejectWithValue(e.message)
        }
    }
);

export const deleteCommentReport = createAsyncThunk<boolean, number, AsyncThunkParams>(
    'commentReports/delete-report',
    async (id, { rejectWithValue }) => {
        try {
            return apiReports.deleteReport(id);
        } catch (e: any) {
            return rejectWithValue(e.message)
        }
    }
);

export const tagReportsSlice = createSlice({
    name: 'tagReports',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchListCommentReports.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(fetchListCommentReports.fulfilled, (state, action) => {
            state.error = null;
            state.isLoading = false;
            state.reports = action.payload.records;
            state.lastPage = action.payload.lastPage;
        });
        builder.addCase(fetchListCommentReports.rejected, (state, action) => {
            state.error = action.payload!;
            state.isLoading = false;
        });
        builder.addCase(deleteComment.pending, (state, action) => {
            state.error = null;
            state.isLoading = true;
            state.reports = state.reports.filter(report => report.comment.id !== action.meta.arg);
        });
        builder.addCase(deleteComment.fulfilled, (state) => {
            state.error = null;
            state.isLoading = false;
        });
        builder.addCase(deleteComment.rejected, (state, action) => {
            state.error = action.payload!;
            state.isLoading = false;
        });
        builder.addCase(deleteCommentReport.pending, (state, action) => {
            state.error = null;
            state.isLoading = true;
            state.reports = state.reports.filter(report => report.id !== action.meta.arg);
        });
        builder.addCase(deleteCommentReport.fulfilled, (state) => {
            state.error = null;
            state.isLoading = false;
        });
        builder.addCase(deleteCommentReport.rejected, (state, action) => {
            state.error = action.payload!;
            state.isLoading = false;
        });
    }
});

export default tagReportsSlice.reducer;

