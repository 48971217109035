import styles from './styles/TableHeader.module.scss';

const QuestionTableHeader = () => {
    return (
        <thead className={styles.tableHeader}>
        <tr>
            <th scope="col" className={styles.headerCol}>
                Question text
            </th>
            <th scope="col" className={styles.headerCol}>
                Question video
            </th>
            <th scope="col" className={styles.headerCol}>
                Creator id
            </th>
            <th scope="col" className={styles.headerCol}>
                Creator name
            </th>
            <th scope="col" className={styles.headerCol}>
                Reporter id
            </th>
            <th scope="col" className={styles.headerCol}>
                Reporter name
            </th>
            <th scope="col" className={styles.headerCol}>
                Report reason
            </th>
            <th scope="col" className={styles.headerButtonsCol}>
                Accept / Decline
            </th>
        </tr>
        </thead>
    )
}

export default QuestionTableHeader;



