import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "./redux";
import { fetchListQuestionReports } from "../store/reducers/QuestionReportSlice";

interface IUsePaginationResponse {
    page: number;
    lastPage: number;
    handleNextPage: VoidFunction;
    handlePrevPage: VoidFunction;
    handleSelectPage: (page: number) => () => void;
}

export const useQuestionsReportsPagination = (): IUsePaginationResponse => {
    const dispatch = useAppDispatch();

    const [page, setPage] = useState(1);
    const { lastPage } = useAppSelector(state => state.questionReports);

    const handleNextPage = useCallback(() => {
        dispatch(fetchListQuestionReports({ page: page + 1, limit: 5 }));
        setPage(page + 1);
    }, [dispatch, page]);

    const handlePrevPage = useCallback(() => {
        dispatch(fetchListQuestionReports({ page: page - 1, limit: 5 }));
        setPage(page - 1);
    }, [dispatch, page]);

    const handleSelectPage = useCallback((pageNumber: number) => () => {
        setPage(pageNumber);
        dispatch(fetchListQuestionReports({ page: pageNumber, limit: 5 }));
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchListQuestionReports({ page: 1, limit: 5 }));
    }, [dispatch]);

    return {
        page,
        lastPage,
        handleNextPage,
        handlePrevPage,
        handleSelectPage
    };
}