import { IInvitationDto } from "../../store/interfaces/invitation.interface";

export const isContainInvite = (invitations: IInvitationDto[], invite: IInvitationDto): boolean => {
    return invitations.some(element => {
        if (element.email === invite.email) {
            return true;
        }

        if (element.phoneNumber && element.phoneNumber === invite.phoneNumber) {
            return true;
        }

        return false;
    });
}