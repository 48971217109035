import { API_URL } from "../urls";
import { ApiService } from './api.service';
import { VideoReportsResponse } from "../types/videoReports.response";
import { Pagination } from "../interfaces/common.interface";

export class VideoReportsApi extends ApiService {

    async getList({ page, limit }: Pagination): Promise<VideoReportsResponse> {
        return this.axiosInstance.get(`${API_URL}/video-reports/list`, { params: { page, size: limit } });
    }

    async deleteVideo(id: number): Promise<boolean> {
        return this.axiosInstance.delete(`${API_URL}/videos/delete/${id}`);
    }

    async deleteReport(id: number): Promise<boolean> {
        return this.axiosInstance.delete(`${API_URL}/video-reports/delete/${id}`);
    }

}