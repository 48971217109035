import Header from '../components/Header/Header';
import { v4 as uuidv4 } from 'uuid';
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useAppSelector } from '../hooks/redux';

const Routing = () => {
  const { isAuth } = useAppSelector(state => state.auth);

  useEffect(() => {
    if (!localStorage.getItem('fingerprint')) {
      const fingerPrint = uuidv4();
      localStorage.setItem('fingerprint', fingerPrint);
    }
  }, [isAuth]);

  return (
      <BrowserRouter>
        {isAuth && <Header/>}
        {isAuth ? <MainRoutes/> : <LoginRoutes/>}
      </BrowserRouter>
  )
}

export default Routing;