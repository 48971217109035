import { API_URL } from "../urls";
import { ApiService } from './api.service';
import { Pagination } from "../interfaces/common.interface";
import { IQuestionReportListResponse } from "../types/questionReports.response";

export class QuestionReportsApi extends ApiService {
    async getList({ page, limit = 10 }: Pagination): Promise<IQuestionReportListResponse> {
        return this.axiosInstance.get(`${API_URL}/question-reports/list`, { params: { page, size: limit } });
    }

    async deleteQuestion(id: number): Promise<boolean> {
        return this.axiosInstance.delete(`${API_URL}/question-reports/delete/${id}`);
    }

    async deleteReport(id: number): Promise<boolean> {
        return this.axiosInstance.delete(`${API_URL}/question-reports/delete/${id}`);
    }

}