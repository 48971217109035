import AuthSlice from "./reducers/AuthSlice";
import AdminSlice from "./reducers/AdminSlice";
import storage from "redux-persist/lib/storage";
import InvitationsSlice from "./reducers/InvitationsSlice";
import VideoReportsSlice from "./reducers/VideoReportsSlice";
import CommentReportsSlice from "./reducers/CommentReportsSlice";
import QuestionReportsSlice from "./reducers/QuestionReportSlice";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE, } from "redux-persist";

const persistConfig = {
    storage,
    version: 1,
    timeout: 0,
    key: 'root',
    blacklist: ['auth', 'reportsVideos',],
};

const authPersistConfig = {
    storage,
    timeout: 0,
    key: 'auth',
    blacklist: ['isLoading', 'error'],
};

const reportsVideosPersistConfig = {
    storage,
    timeout: 0,
    key: 'reportsVideos',
    blacklist: ['isLoading', 'error'],
};

const adminsPersistConfig = {
    storage,
    timeout: 0,
    key: 'admins',
    blacklist: ['isLoading', 'error'],
};

const invitationsPersistConfig = {
    storage,
    timeout: 0,
    key: 'invitations',
    blacklist: ['isLoading', 'error', 'invitations'],
};

const reportsQuestionsPersistConfig = {
    storage,
    timeout: 0,
    key: 'reportsQuestions',
    blacklist: ['isLoading', 'error'],
};

const reportsCommentsPersistConfig = {
    storage,
    timeout: 0,
    key: 'reportsComments',
    blacklist: ['isLoading', 'error'],
};

const rootReducer = combineReducers({
    auth: persistReducer(authPersistConfig, AuthSlice),
    admins: persistReducer(adminsPersistConfig, AdminSlice),
    invitations: persistReducer(invitationsPersistConfig, InvitationsSlice),
    videoReports: persistReducer(reportsVideosPersistConfig, VideoReportsSlice),
    commentReports: persistReducer(reportsCommentsPersistConfig, CommentReportsSlice),
    questionReports: persistReducer(reportsQuestionsPersistConfig, QuestionReportsSlice),
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});

export const persist = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
