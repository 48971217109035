import { CreateInvite } from "./interfaces";
import TextInput from "../Input/TextInput";
import Button from "../Button/Button";
import Popover from "../Tooltip/Tooltip";
import TextArea from "../Input/TextArea";

const CreateInviteForms = ({ onChange, onCreate, values, disabled, showError }: CreateInvite) => {
    const handleCreateInvite = () => {
        onCreate();
    }

    return (
        <div>
            <div className="flex mt-[50px] w-[100%]">
                <div className="px-1 w-[50%]">
                    <TextInput name="email" value={values.email} onChange={onChange} placeholder="Email"/>
                </div>
                <div className="px-1 w-[50%] flex">
                    <TextInput
                        name="phoneNumber"
                        onChange={onChange}
                        placeholder="PhoneNumber"
                        value={values.phoneNumber}
                    />
                    <div className="pl-2 relative">
                        <Popover show={showError}/>
                        <Button disabled={disabled} onClick={handleCreateInvite} rounded>+Add</Button>
                    </div>
                </div>

            </div>
            <div className="py-5 px-1 flex w-full">
                <div className="w-full">
                    <TextArea rows={2} cols={2} name="message" value={values.message} onChange={onChange}
                              placeholder="Invite message"/>
                </div>
            </div>
        </div>
    );
}

export default CreateInviteForms;