import Button from "../Button/Button";
import styles from './Invitations.module.scss';
import { InvitationShowProps } from "./interfaces";
import { ESizeButton, EVariationButton } from "../Button/Button.interface";
import { useCallback } from "react";
import Message from "../Message";

const InvitationShow = ({ invite, onDelete }: InvitationShowProps) => {
    const handleDeleteInvite = useCallback((id: string) => () => {
        onDelete(id);
    }, [onDelete]);

    return (
        <div className={styles.invitationShow}>
            <h3 className={styles.item}>{invite.email}</h3>
            <h3 className={styles.item}>{invite.phoneNumber}</h3>
            <div className={styles.item}>
                {invite.message && <Message content={invite.message}/>}
            </div>
            <Button
                rounded
                size={ESizeButton.small}
                variation={EVariationButton.error}
                onClick={handleDeleteInvite(invite.id)}
            >
                Delete
            </Button>
        </div>
    );
}

export default InvitationShow;