import { API_URL } from "../urls";
import { ApiService } from './api.service';
import axios, { AxiosResponse } from 'axios';
import { SuccessResponse, TokenPairResponse } from "../types/auth.response";
import { IApproveVerificationRequest } from "../types/auth.type";
import { ILogin } from "../interfaces/auth.interface";

export class AuthApi extends ApiService {
    static async authEmail(email: string): Promise<AxiosResponse> {
        return axios.post<AxiosResponse<SuccessResponse>>(`${API_URL}/auth/email-auth`, { email });
    }

    static async login(data: ILogin): Promise<TokenPairResponse> {
        const response = await axios
            .post<ILogin, AxiosResponse<TokenPairResponse>>(`${API_URL}/admin-auth/login`, data);

        return response.data;
    }

    static async compareVerificationCode(data: IApproveVerificationRequest): Promise<AxiosResponse> {
        return axios.post<AxiosResponse<TokenPairResponse>>(`${API_URL}/auth/approve-auth`, data);
    }

    async refreshToken(data: { accessToken: string, refreshToken: string }): Promise<AxiosResponse> {
        return this.axiosInstance.post(`${API_URL}/admin-auth/refresh-token`, data);
    }
}