import NavButton from "./NavButton";
import React, { useCallback } from 'react';
import { useAppDispatch } from "../../hooks/redux";
import { logoutAsync } from "../../store/reducers/AuthSlice";
import { useLocation, useNavigate } from "react-router-dom";

const Header = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const currentPath = pathname.replace('/', '');

    const handleNavigate = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        const routeName = e.currentTarget.name;
        navigate(routeName);
    }, [navigate]);

    const handleLogOut = useCallback(() => {
        dispatch(logoutAsync());
    }, [dispatch]);

    return (
        <nav className="flex items-center h-[50px] bg-gray-400 text-white">
            <NavButton onNavigate={handleNavigate} name={'videos'} selected={currentPath === 'videos'}>
                Video Reports
            </NavButton>
            <NavButton onNavigate={handleNavigate} name={'questions'} selected={currentPath === 'questions'}>
                Questions reports
            </NavButton>
            <NavButton onNavigate={handleNavigate} name={'comments'} selected={currentPath === 'comments'}>
                Comments reports
            </NavButton>
            <NavButton onNavigate={handleNavigate} name={'users'} selected={currentPath === 'users'}>
                Users
            </NavButton>
            <NavButton onNavigate={handleNavigate} name={'invitations'} selected={currentPath === 'invitations'}>
                Invitations
            </NavButton>
            <NavButton onNavigate={handleNavigate} name={'deleted-users'} selected={currentPath === 'deleted-users'}>
              Deleted Users
            </NavButton>
            <button
                onClick={handleLogOut}
                className="h-full hover:opacity-100 text-center bg-red-800 opacity-50 w-[20%]"
            >
                Logout
            </button>
        </nav>
    )
}

export default Header;