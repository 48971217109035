import { memo, useCallback } from "react";
import { isUserModel } from "./types";
import { UserModel } from "../../store/models/User.model";
import { AdminModel } from "../../store/models/Admin.model";

import styles from './styles/Report.module.scss';
import { useAppSelector } from "../../hooks/redux";

interface IUserProps {
  user: AdminModel | UserModel;
  onClick?: (id: number) => void;
}

const User = memo(({ user, onClick }: IUserProps) => {

  const handleAcceptDeletion = useCallback(() => {
    console.log(user, onClick);
    onClick && onClick(user.id);
  }, [onClick, user]);

  const { isHardDeleting } = useAppSelector(state => state.admins);

  return (
      <tbody>
      <tr className={styles.reportRowsWrapper}>
        <td className={styles.tableRow}>
          {user.userName}
        </td>
        <td scope="row" className={styles.tableRow}>
          {!user?.phoneNumber ? '-' : user.phoneNumber}
        </td>
        <td className={styles.tableRow}>
          {user.email}
        </td>
        {
          isUserModel(user) ?
              <>
                <td className={styles.tableRow}>
                  {new Date(user.deletedAt as Date).toLocaleDateString()}
                </td>
                <td className={styles.tableRow}>
                  <div className={styles.buttonsWrapper}>
                    <button disabled={isHardDeleting} onClick={handleAcceptDeletion} name={'warning'}
                            className={styles.acceptButton}>
                      {isHardDeleting ? 'Deleting' : 'Accept'}
                    </button>
                  </div>
                </td>
              </>
              : null
        }
      </tr>
      </tbody>
  )
})

export default User;



