import styles from './Video.module.scss';
import { VideoHTMLAttributes } from 'react';

interface IVideoProps extends VideoHTMLAttributes<HTMLVideoElement> {
  url: string;
}

const Video = ({ url, ...props } :IVideoProps) => {
  return (
    <>
      <video className={styles.videoStyle} {...props}>
        <source src={url} type="video/mp4" />
      </video>
    </>
  )
}

export default Video;