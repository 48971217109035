import { API_URL } from "../urls";
import { ApiService } from './api.service';
import { AdminModel } from "../models/Admin.model";
import { Pagination, SendWarning } from "../interfaces/common.interface";
import { ICreateAdmin } from "../interfaces/admin.interface";
import { AdminsListResponse } from "../types/admins.response";
import { UserModel } from "../models/User.model";
import { SuccessResponse } from "../types/auth.response";

export class AdminsApi extends ApiService {
  async getList({ page, limit = 10 }: Pagination): Promise<AdminsListResponse> {
    return this.axiosInstance.get(`${API_URL}/admins/list`, { params: { page, limit } });
  }

  async create(user: ICreateAdmin): Promise<AdminModel> {
    return this.axiosInstance.post(`${API_URL}/admins/create`, user);
  }

  async sendWarning(warning: SendWarning): Promise<AdminModel> {
    return this.axiosInstance.post(`${API_URL}/messages/send`, warning);
  }

  async getSoftDeletedUsers(): Promise<UserModel[]> {
    return this.axiosInstance.get(`${API_URL}/users/list-soft-deleted`);
  }

  async forceDeleteUser(id: number): Promise<SuccessResponse> {
    return this.axiosInstance.delete(`${API_URL}/users/force-delete/${id}`);
  }
}