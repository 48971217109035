import { API_URL } from "../urls";
import { ApiService } from './api.service';
import { Pagination } from "../interfaces/common.interface";
import { ICommentReportListResponse } from "../types/commentReports.response";


export class CommentReportsApi extends ApiService {

    async getList({ page, limit = 10 }: Pagination): Promise<ICommentReportListResponse> {
        return this.axiosInstance.get(`${API_URL}/comment-reports/list`, { params: { limit, page } });
    }

    async deleteComment(id: number): Promise<boolean> {
        return this.axiosInstance.delete(`${API_URL}/comments/delete/${id}`);
    }

    async deleteReport(id: number): Promise<boolean> {
        return this.axiosInstance.delete(`${API_URL}/comment-reports/delete/${id}`);
    }

}