export const classes = {
    rounded: 'rounded-[5px]',
    disabled: 'opacity-50 cursor-not-allowed',
    size: {
        normal: 'px-4 py-2',
        small: 'px-2 py-1 text-xs',
        large: 'px-8 py-3 text-lg',
    },
    variant: {
        success: 'bg-green-500 hover:bg-green-800 text-white',
        error: 'bg-red-500 hover:bg-red-800 focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 text-white',
        primary: 'bg-blue-500 hover:bg-blue-800 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 text-white',
        warning: 'bg-yellow-500 hover:bg-yellow-800 focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 text-white',
        secondary: 'bg-gray-200 hover:bg-gray-800 focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 text-gray-900 hover:text-white',
    },
    outline: 'bg-none',
}