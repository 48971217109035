import { useAppDispatch } from "../../hooks/redux";
import { useEffect } from "react";
import UsersList from "../../components/Users/UsersList";
import { EPaginationType } from "../../hooks/usePagination";
import Pagination from '../../components/Pagination/Pagination';
import UserTableHeader from "../../components/Users/UsersHeader";
import { getSoftDeletedUsers } from "../../store/reducers/AdminSlice";

import styles from '../Users/Users.module.scss';

const DeletedUsers = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSoftDeletedUsers());
  }, [dispatch]);

  return (
      <div className={styles.wrapper}>
        <table className={styles.tableList}>
          <UserTableHeader/>
          <UsersList isDeletedUsers/>
        </table>
        <div className="mt-[20px] h-[50px] absolute bottom-10 left-0 right-0 flex justify-center content-center">
          <Pagination type={EPaginationType.USERS}/>
        </div>
      </div>
  )
}

export default DeletedUsers;

