import Modal from '../Modal/Modal';
import Video from '../Videos/Video';
import { useCallback, useState } from 'react';
import styles from '../QuestionReports/styles/Report.module.scss';
import { useAppDispatch } from '../../hooks/redux';
import { VideoReportModel } from '../../store/models/VideoReport.model';
import { deleteReport } from '../../store/reducers/VideoReportsSlice';
import AcceptReport from "../AcceptReport/AcceptReport";
import { EReportType } from "../AcceptReport/AcceptReport.interface";

interface IReportProps {
    report: VideoReportModel;
}

const Report = ({ report }: IReportProps) => {
    const [showModal, setShowModal] = useState(false);
    const [showModalReport, setShowModalReport] = useState(false);

    const dispatch = useAppDispatch();

    const handleModal = useCallback(() => {
        setShowModal(!showModal);
    }, [showModal]);

    const handleAcceptReport = useCallback(() => {
        setShowModalReport(!showModalReport);
    }, [showModalReport]);

    const handleDeclineReport = useCallback((id: number) => () => {
        dispatch(deleteReport(id));
    }, [dispatch]);

    return (
        <tbody>
        <tr className={styles.reportRowsWrapper}>
            <th scope="row" className={styles.videoRow}>
                <button onClick={handleModal}>
                    <Video url={report.video.url}/>
                </button>
                <Modal isVisible={showModal} onClose={handleModal}>
                    <Video autoPlay={true} url={report.video.url} className={styles.videoModal}/>
                </Modal>
            </th>
            <td className={styles.tableRow}>
                {report.video.question.description}
            </td>
            <td className={styles.tableRow}>
                {report.video.createdBy}
            </td>
            <td className={styles.tableRow}>
                {report.video.creator?.userName}
            </td>
            <td className={styles.tableRow}>
                {report.reporter.id}
            </td>
            <td className={styles.tableRow}>
                {report.reporter.userName}
            </td>
            <td className={styles.tableRow}>
                {report.text}
            </td>
            <td className={styles.tableRow}>
                <div className={styles.buttonsWrapper}>
                    <div>
                        <button name={'warning'} onClick={handleAcceptReport} className={styles.acceptButton}>
                            Accept
                        </button>
                        <Modal isVisible={showModalReport} onClose={handleAcceptReport}>
                            <AcceptReport reportType={EReportType.VIDEO} contentOwner={report!.video!.creator!}
                                          contentId={report.video.id}/>
                        </Modal>
                    </div>
                    <button onClick={handleDeclineReport(report.id)} className={styles.declineButton}>
                        Decline
                    </button>
                </div>
            </td>
        </tr>
        </tbody>
    )
}

export default Report;



