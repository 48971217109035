import { UserModel } from "../../store/models/User.model";

export enum EReportType {
    VIDEO = 'VIDEO',
    COMMENT = 'COMMENT',
    QUESTION = 'QUESTION',
}

type ReportType = EReportType.COMMENT | EReportType.QUESTION | EReportType.VIDEO;

export interface AcceptReportProps {
    contentId: number;
    reportType: ReportType;
    contentOwner: UserModel;
}