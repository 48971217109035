import React, { ButtonHTMLAttributes, FC, MouseEventHandler, ReactNode } from "react";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
    selected: boolean;
    children: ReactNode;
    onNavigate: MouseEventHandler;
}

const NavButton: FC<Props> = ({ children, selected, onNavigate, ...props }: Props) => {
    return (
        <button
            {...props}
            onClick={onNavigate}
            className={`${selected && 'bg-gray-600'} hover:bg-gray-600 h-full bg-gray-400 text-center w-[50%]`}
        >
            {children}
        </button>
    )
}

export default NavButton;