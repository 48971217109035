import { useCallback, useState } from 'react';
import styles from './styles/Report.module.scss';
import { useAppDispatch } from '../../hooks/redux';
import { CommentReportModel } from "../../store/models/CommentReport.model";
import { deleteCommentReport } from "../../store/reducers/CommentReportsSlice";
import Modal from "../Modal/Modal";
import AcceptReport from "../AcceptReport/AcceptReport";
import { EReportType } from "../AcceptReport/AcceptReport.interface";
import Video from "../Videos/Video";

interface IReportProps {
    report: CommentReportModel;
}

const CommentReport = ({ report }: IReportProps) => {
    const dispatch = useAppDispatch();
    const [showModal, setShowModal] = useState(false);
    const [showModalReport, setShowModalReport] = useState(false);

    const handleAcceptReport = useCallback(() => {
        setShowModalReport(!showModalReport);
    }, [showModalReport]);

    const handleModal = useCallback(() => {
        setShowModal(!showModal);
    }, [showModal]);

    const handleDeclineReport = useCallback((id: number) => () => {
        dispatch(deleteCommentReport(id));
    }, [dispatch]);

    return (
        <tbody>
        <tr className={styles.reportRowsWrapper}>
            <th scope="row" className={styles.videoRow}>
                <button onClick={handleModal}>
                    <Video url={report.comment.video.url}/>
                </button>
                <Modal isVisible={showModal} onClose={handleModal}>
                    <Video autoPlay={true} url={report.comment.video.url} className={styles.videoModal}/>
                </Modal>
            </th>
            <td className={styles.tableRow}>
                {report.comment.comment}
            </td>
            <td className={styles.tableRow}>
                {report.comment.userId}
            </td>
            <td className={styles.tableRow}>
                {report.comment.commenter?.userName}
            </td>
            <td className={styles.tableRow}>
                {report.reporter.id}
            </td>
            <td className={styles.tableRow}>
                {report.reporter.userName}
            </td>
            <td className={styles.tableRow}>
                {report.text}
            </td>
            <td className={styles.tableRow}>
                <div className={styles.buttonsWrapper}>
                    <div>
                        <button name={'warning'} onClick={handleAcceptReport} className={styles.acceptButton}>
                            Accept
                        </button>
                        <Modal isVisible={showModalReport} onClose={handleAcceptReport}>
                            <AcceptReport
                                contentId={report.comment.id}
                                reportType={EReportType.COMMENT}
                                contentOwner={report!.comment!.commenter!}
                            />
                        </Modal>
                    </div>
                    <button onClick={handleDeclineReport(report.id)} className={styles.declineButton}>
                        Decline
                    </button>
                </div>
            </td>
        </tr>
        </tbody>
    )
}

export default CommentReport;



