import { v4 as uuidv4 } from 'uuid';
import Button from "../../components/Button/Button";
import { inviteMessage } from "../../utils/constans";
import { ChangeEvent, useCallback, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { isContainInvite } from "../../utils/helpers/isContainInvite";
import InvitationsList from "../../components/Invitations/InvitationsList";
import { InviteFormsValue } from "../../components/Invitations/interfaces";
import { EVariationButton } from "../../components/Button/Button.interface";
import CreateInviteForms from "../../components/Invitations/CreateInviteForms";
import InvitationsHeader from "../../components/Invitations/InvitationsHeader";
import { createInvite, sendInvitations } from "../../store/reducers/InvitationsSlice";

const Invitations = () => {
    const dispatch = useAppDispatch();
    const { invitations, isLoading } = useAppSelector(state => state.invitations);

    const [show, setShow] = useState<boolean>(false);
    const [values, setValues] = useState<InviteFormsValue>({
        email: '',
        message: '',
        phoneNumber: '',
    });

    const handleAddInvite = useCallback(() => {
        const id = uuidv4();
        const invite = { id, ...values };

        const isExist = isContainInvite(invitations, invite);

        if (!isExist) {
            dispatch(createInvite(invite));
            setValues({
                email: '',
                message: '',
                phoneNumber: '',
            });
        } else {
            setShow(!show);
        }
    }, [dispatch, values, invitations, show]);

    const handleSubmit = useCallback(() => {
        if (invitations.length) {
            const invitationsDto = invitations.map(invite => ({
                email: invite.email,
                phoneNumber: invite.phoneNumber,
                message: invite?.message?.length ? invite?.message : inviteMessage,
            }));
            dispatch(sendInvitations(invitationsDto));
        }
    }, [dispatch, invitations]);

    const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const name = e.target.name;

        setValues(prev => ({ ...prev, [name]: value }));
        setShow(false);
    }, []);

    return (
        <div className="w-full h-full flex justify-center">
            <div className="pt-[30px] py-4 w-[70%] h-full min-h-[100vh]">
                <InvitationsHeader/>
                <InvitationsList invitations={invitations}/>
                <CreateInviteForms
                    values={values}
                    showError={show}
                    onChange={handleChange}
                    onCreate={handleAddInvite}
                    disabled={!values.email.length && !values.phoneNumber.length}
                />
                <div className="flex justify-center pt-5">
                    <Button
                        rounded
                        onClick={handleSubmit}
                        disabled={!invitations.length}
                        variation={EVariationButton.success}
                    >
                        {isLoading ? 'Sending' : 'Submit'}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default Invitations;