import { ChangeEvent, MouseEvent, useCallback, useState } from 'react';
import { IoInformationCircleOutline } from "react-icons/io5";
import Button from "../Button/Button";
import { useAppDispatch } from "../../hooks/redux";
import { EVariationButton } from "../Button/Button.interface";
import { sendWarning } from "../../store/reducers/AdminSlice";
import { deleteVideo } from "../../store/reducers/VideoReportsSlice";
import { deleteComment } from "../../store/reducers/CommentReportsSlice";
import { deleteQuestion } from "../../store/reducers/QuestionReportSlice";
import { AcceptReportProps, EReportType } from "./AcceptReport.interface";
import TextArea from "../Input/TextArea";

const AcceptReport = ({ contentId, contentOwner, reportType }: AcceptReportProps) => {
    const dispatch = useAppDispatch();
    const [message, setMessage] = useState<string>('');

    const handleAcceptReport = useCallback((e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (reportType === EReportType.VIDEO) dispatch(deleteVideo(contentId));
        if (reportType === EReportType.QUESTION) dispatch(deleteQuestion(contentId));
        if (reportType === EReportType.COMMENT) dispatch(deleteComment(contentId));

        dispatch(sendWarning({ email: contentOwner.email, message }));

        setMessage('');
    }, [contentId, reportType, contentOwner, message, dispatch]);

    const handleChangeMessage = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
        setMessage(e.target.value);
    }, []);

    return (
        <div className="p-2 h-[250px] w-full flex flex-col items-center justify-between">
            <div className="flex w-full justify-center">
                <div className="group relative flex cursor-pointer">
                    <h1 className="text-orange-300 items-end">
                        <IoInformationCircleOutline size={24}/>
                    </h1>
                    <div
                        className="w-[300px] absolute left-[25px] scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">
                        <h2 className="text-orange-300">Once you accept the report a message will be sent to the content
                            owner with the warning you
                            entered</h2>
                    </div>
                </div>
                <h1 className="text-center flex-1 text-gray-50">Write the reason for deleting the record.</h1>
            </div>
            <div className="w-full">
                <TextArea
                    value={message}
                    placeholder="Message"
                    style={{ resize: 'none' }}
                    onChange={handleChangeMessage}
                />
            </div>
            <div>
                <Button
                    rounded
                    disabled={!message.length}
                    onClick={handleAcceptReport}
                    variation={EVariationButton.success}
                >
                    Accept
                </Button>
            </div>
        </div>

    );
}

export default AcceptReport;