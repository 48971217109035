import { useUsersPagination } from "./useUsersPagination";
import { useVideoReportsPagination } from "./useVideoReportsPagination";
import { useCommentsReportsPagination } from "./useCommentsReportsPagination";
import { useQuestionsReportsPagination } from "./useQuestionReportsPagination";

export enum EPaginationType {
    USERS = 'USERS',
    DELETED_USERS = 'DELETED_USERS',
    VIDEO_REPORTS = 'VIDEO_REPORTS',
    QUESTION_REPORTS = 'QUESTION_REPORTS',
    COMMENTS_REPORTS = 'COMMENTS_REPORTS',
}

export type PaginationType =
    EPaginationType.USERS
    | EPaginationType.VIDEO_REPORTS
    | EPaginationType.DELETED_USERS
    | EPaginationType.QUESTION_REPORTS
    | EPaginationType.COMMENTS_REPORTS;

interface IUsePaginationProps {
    type: PaginationType
}

interface IPaginationResponse {
    page: number;
    lastPage: number;
    handleNextPage: VoidFunction;
    handlePrevPage: VoidFunction;
    handleSelectPage: (page: number) => () => void;
}

export const usePagination = ({ type }: IUsePaginationProps): IPaginationResponse => {
    let pagination;
    const paginationUsersData = useUsersPagination();
    const paginationVideoData = useVideoReportsPagination();
    const paginationCommentsData = useCommentsReportsPagination();
    const paginationQuestionData = useQuestionsReportsPagination();

    if (type === EPaginationType.USERS) {
        pagination = paginationUsersData;
    }

    if (type === EPaginationType.VIDEO_REPORTS) {
        pagination = paginationVideoData;
    }

    if (type === EPaginationType.QUESTION_REPORTS) {
        pagination = paginationQuestionData;
    }

    if (type === EPaginationType.COMMENTS_REPORTS) {
        pagination = paginationCommentsData;
    }

    if (!pagination) {
        return {
            page: 1,
            lastPage: 1,
            handleNextPage: () => null,
            handlePrevPage: () => null,
            handleSelectPage: () => () => null,
        };
    }

    return pagination;
}